@use "./global/Vars.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --color-tailwind-primary: 53 98 255 !important; /* primary-500 */
    --color-tailwind-primary--hover: 38 70 181 !important; /* primary-700 */
    --color-tailwind-primary--pressed: 192 206 255 !important; /* primary-100 */
    --color-tailwind-primary-light: 208 229 253 !important;
    --color-tailwind-primary-25: 241 244 255 !important;
    --color-tailwind-primary-50: 235 239 255 !important;
    --color-tailwind-primary-100: 192 206 255 !important;
    --color-tailwind-primary-200: 162 183 255 !important;
    --color-tailwind-primary-300: 120 150 255 !important;
    --color-tailwind-primary-400: 93 129 255 !important;
    --color-tailwind-primary-500: 53 98 255 !important;
    --color-tailwind-primary-600: 48 89 232 !important;
    --color-tailwind-primary-700: 38 70 181 !important;
    --color-tailwind-primary-800: 29 54 140 !important;
    --color-tailwind-primary-900: 22 41 107 !important;

    --color-tailwind-secondary: 86 63 240 !important; /* secondary-500 */
    --color-tailwind-secondary--hover: 67 49 186 !important; /* secondary-700 */
    --color-tailwind-secondary--pressed: 231 230 252 !important; /* secondary-100 */
    --color-tailwind-secondary-25: 248 248 255 !important;
    --color-tailwind-secondary-50: 243 243 254 !important;
    --color-tailwind-secondary-100: 231 230 252 !important;
    --color-tailwind-secondary-200: 205 202 249 !important;
    --color-tailwind-secondary-300: 174 168 246 !important;
    --color-tailwind-secondary-400: 138 127 243 !important;
    --color-tailwind-secondary-500: 86 63 240 !important;
    --color-tailwind-secondary-600: 77 56 215 !important;
    --color-tailwind-secondary-700: 67 49 186 !important;
    --color-tailwind-secondary-800: 54 40 152 !important;
    --color-tailwind-secondary-900: 38 28 107 !important;

    --color-tailwind-tertiary: 255 193 0 !important; /* tertiary-500 */
    --color-tailwind-tertiary--hover: 181 137 0 !important; /* tertiary-700 */
    --color-tailwind-tertiary--pressed: 255 236 176 !important; /* tertiary-100 */
    --color-tailwind-tertiary-50: 233 235 248 !important;
    --color-tailwind-tertiary-100: 255 236 176 !important;
    --color-tailwind-tertiary-200: 255 226 138 !important;
    --color-tailwind-tertiary-300: 255 213 84 !important;
    --color-tailwind-tertiary-400: 255 205 51 !important;
    --color-tailwind-tertiary-500: 255 193 0 !important;
    --color-tailwind-tertiary-600: 232 176 0 !important;
    --color-tailwind-tertiary-700: 181 137 0 !important;
    --color-tailwind-tertiary-800: 140 106 0 !important;
    --color-tailwind-tertiary-900: 107 81 0 !important;

    --color-tailwind-success: 0 195 147 !important; /* success-500 */
    --color-tailwind-success-hover: 0 138 104 !important; /* success-700 */
    --color-tailwind-success: 0 195 147 !important;
    /* success-500 */
    --color-tailwind-success-hover: 0 138 104 !important;
    /* success-700 */
    --color-tailwind-success-1: 0 138 104 !important;
    --color-tailwind-success-50: 230 249 244 !important;
    --color-tailwind-success-100: 176 236 222 !important;
    --color-tailwind-success-200: 138 227 205 !important;
    --color-tailwind-success-300: 84 215 183 !important;
    --color-tailwind-success-400: 51 207 169 !important;
    --color-tailwind-success-500: 0 195 147 !important;
    --color-tailwind-success-600: 0 177 134 !important;
    --color-tailwind-success-700: 0 138 104 !important;
    --color-tailwind-success-800: 0 107 81 !important;
    --color-tailwind-success-900: 0 82 62 !important;

    --color-tailwind-warning: 255 226 91 !important; /* warning-500 */
    --color-tailwind-warning-hover: 181 160 65 !important; /* warning-700 */
    --color-tailwind-warning-50: 255 252 239 !important;
    --color-tailwind-warning-100: 255 246 204 !important;
    --color-tailwind-warning-200: 255 242 180 !important;
    --color-tailwind-warning-300: 255 236 145 !important;
    --color-tailwind-warning-400: 255 232 124 !important;
    --color-tailwind-warning-500: 255 226 91 !important;
    --color-tailwind-warning-600: 232 206 83 !important;
    --color-tailwind-warning-700: 181 160 65 !important;
    --color-tailwind-warning-800: 140 124 50 !important;
    --color-tailwind-warning-900: 107 95 38 !important;

    --color-tailwind-error: 229 64 85 !important; /* error-500 */
    --color-tailwind-error-hover: 163 45 60 !important; /* error-700 */
    --color-tailwind-error-50: 252 236 238 !important;
    --color-tailwind-error-100: 247 196 202 !important;
    --color-tailwind-error-200: 243 167 177 !important;
    --color-tailwind-error-300: 238 127 141 !important;
    --color-tailwind-error-400: 234 102 119 !important;
    --color-tailwind-error-500: 229 64 85 !important;
    --color-tailwind-error-600: 208 58 77 !important;
    --color-tailwind-error-700: 163 45 60 !important;
    --color-tailwind-error-800: 126 35 47 !important;
    --color-tailwind-error-900: 96 27 36 !important;

    --color-tailwind-grey: 91 65 65 !important;
    --color-tailwind-grey--hover: 69 90 100 !important; /* grey-700 */
    --color-tailwind-grey-1: 51 51 51 !important;
    --color-tailwind-grey-2: 79 79 79 !important;
    --color-tailwind-grey-3: 130 130 130 !important;
    --color-tailwind-grey-4: 189 189 189 !important;
    --color-tailwind-grey-5: 224 224 224 !important;
    --color-tailwind-grey-6: 242 242 242 !important;
    --color-tailwind-grey-10: 254 254 254 !important;

    --color-tailwind-grey-25: 246 246 246 !important;
    --color-tailwind-grey-50: 236 239 241 !important;
    --color-tailwind-grey-100: 207 216 220 !important;
    --color-tailwind-grey-200: 176 190 197 !important;
    --color-tailwind-grey-300: 144 164 174 !important;
    --color-tailwind-grey-400: 120 144 156 !important;
    --color-tailwind-grey-500: 96 125 139 !important;
    --color-tailwind-grey-600: 84 110 122 !important;
    --color-tailwind-grey-700: 69 90 100 !important;
    --color-tailwind-grey-800: 51 71 79 !important;
    --color-tailwind-grey-900: 38 50 56 !important;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }

  .trenes {
    --color-tailwind-primary: 48 183 255 !important; /* primary-500 */
    --color-tailwind-primary--hover: 0 118 180 !important; /* primary-700 */
    --color-tailwind-primary--pressed: 197 235 255 !important; /* primary-100 */
    --color-tailwind-primary-50: 234 248 255 !important;
    --color-tailwind-primary-100: 197 235 255 !important;
    --color-tailwind-primary-500: 48 183 255 !important;
    --color-tailwind-primary-600: 21 152 221 !important;
    --color-tailwind-primary-700: 0 118 180 !important;
    --color-tailwind-primary-800: 0 91 139 !important;
    --color-tailwind-primary-900: 0 65 98 !important;
  }
}

%shadows {
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body,
p {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

body {
  width: 99vw;
  overflow-x: hidden;
  min-height: 100vh;
}

textarea,
input {
  font-size: 1rem;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

li {
  list-style-type: none;
}

a,
a:hover {
  text-decoration: none;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.pointer {
  cursor: pointer;
}

.remove-paddings {
  padding-right: 0px !important;
}

.react-joyride__tooltip button[data-action="primary"] {
  background: none !important;
  outline: none;
  &:hover {
    color: #234ce3 !important;
  }
}

.react-joyride {
  position: absolute;
}
